import React, { useEffect, useState } from "react"
import { AddressElement, Elements, LinkAuthenticationElement, PaymentElement } from "@stripe/react-stripe-js"
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Loader from "../components/Loader";
import Failed from "./Failed";
import useApiGet from "../hooks/useApiGet";
import { loadStripe } from "@stripe/stripe-js";

export function Payment({ clientSecret, refreshPayment, paymentSuccess, onPayment, isLoading }) {
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState(null)
    const [email, setEmail] = useState({})

    const handleEmailChange = (event) => {
        setEmail(event.value.email)
    }

    useEffect(() => {
        if (refreshPayment > 0)
            handlePayment()
    }, [refreshPayment])

    useState(() => {
        if (paymentSuccess === false)
            setError({ message: "Unfortunately something went wrong with your payment. Please try again." })
    }, [])

    const handlePayment = async () => {
        var res = await elements.submit()
        if (res.hasOwnProperty("error"))
            return;

        onPayment(true, false)
        const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${window.location.href}`,
                receipt_email: email,
            },
            redirect: "if_required",
        });

        if (error) {
            setError(error)
            onPayment(false, false)
            return
        }

        onPayment(false, true)
    }

    const handleTryToPayAgain = () => {
        setError(null)
    }

    return (
        <>
            <div className="payment-stage" style={{ display: !error ? 'flex' : 'none' }}>
                <div className="stripe-div">
                    <LinkAuthenticationElement
                        onChange={handleEmailChange} />

                    <div className="shipping-div">
                        <AddressElement
                            options={{ mode: 'shipping' }} />
                    </div>

                    <PaymentElement />
                </div >
                <button className="make-payment-button" onClick={handlePayment} ><i className="fas fa-lock" /><p>Click to pay</p></button>
            </div>
            <div
                style={{ display: isLoading && !error ? 'flex' : 'none' }}
                className="processing-payment">
                <Loader />
                <p>Processig your payment</p>
            </div>

            {
                error &&
                <div className="failed-payment">
                    <Failed message={error.message} onTryToPayAgain={handleTryToPayAgain} />
                </div>
            }
        </>
    )
}

export default function PaymentContainer({
    amountToStripe,
    quantity,
    product,
    selectedSku,
    refreshPayment,
    paymentSuccess,
    onPayment,
    isLoading }) {
    const [publishableKeyData] = useApiGet('payments/config')
    const [clientSecret, setClientSecret] = useState(null)
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecretData] = useApiGet(`payments/create-payment-intent?amount=${amountToStripe}&quantity=${quantity}&aliexpressProductId=${product.aliexpressProductId}&productId=${product.productId}&skuIndex=${selectedSku?.index}`, false)

    const appearance = {
        theme: 'flat',
        variables: {
            fontFamily: "Montserrat, sans-serif",
            fontLineHeight: '1.2',
            borderRadius: '0.5rem',
            colorBackground: '#F6F8FA',
        },
        rules: {
            '.Input': { padding: '1rem' },
            '.Label': { fontWeight: '500', fontSize: '1rem', fontFamily: "Montserrat, sans-serif" }
        }
    }

    useEffect(() => {
        if (publishableKeyData !== null)
            setStripePromise(loadStripe(publishableKeyData.publishableKey));
    }, [publishableKeyData])

    useEffect(() => {
        setClientSecret(clientSecretData?.clientSecret);
    }, [clientSecretData])

    useEffect(() => {
        if (!stripePromise || !clientSecret)
            return;

        stripePromise.then(async (stripe) => {
            await stripe.retrievePaymentIntent(clientSecret);
        });
    }, [stripePromise, clientSecret]);

    if (!stripePromise || !clientSecret)
        return null;

    return (
        <Elements
            stripe={stripePromise}
            clientSecret={clientSecret}
            options={{
                automatic_payment_methods: { enabled: true },
                appearance,
                loader: 'auto',
                setup_future_usage: 'off_session',
                amount: amountToStripe,
                currency: "usd",
                mode: "payment",
            }}>
            <Payment
                clientSecret={clientSecret}
                paymentSuccess={paymentSuccess}
                onPayment={onPayment}
                isLoading={isLoading}
                refreshPayment={refreshPayment} />
        </Elements>
    )
}