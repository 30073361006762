import React, { useEffect, useState } from "react";
import ProductTag from "./ProductTag";
import { useNavigate } from "react-router-dom";
import useApiGet from "../hooks/useApiGet";
import calculateDiscountPercentage from "../utilities/calculateDiscountPercentage";

export default function VerticalRecommendations({ onLoaded }) {
    const [loadedProducts] = useApiGet("products/recommended?limit=12")
    const navigate = useNavigate()

    const [products, setProducts] = useState([])

    useEffect(() => {
        if (loadedProducts != null && onLoaded != null)
            onLoaded()
        // eslint-disable-next-line
    }, [loadedProducts])

    useEffect(() => {
        const handleResize = () => {
            const container = document.querySelector('.vertical-recommendation-div');
            const computedStyle = window.getComputedStyle(container);
            const containerWidth = container.clientWidth;
            const columnWidth = parseFloat(computedStyle.getPropertyValue('grid-template-columns').split(' ')[0]);
            const numberOfColumns = Math.floor(containerWidth / columnWidth);

            if (loadedProducts !== null && loadedProducts.length > 1) {
                const productsToDisplay = loadedProducts.length - (loadedProducts.length % numberOfColumns);
                setProducts(loadedProducts.slice(0, productsToDisplay))
            }

        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () =>
            window.removeEventListener('resize', handleResize);
    }, [loadedProducts])

    return (
        <div className="vertical-recommendation-div">
            {
                products?.map((product, idx) => (
                    <div onClick={() => navigate(`../product/${product.productId}`)} key={product.productId} className="recommendation-item">
                        {product.discountedPrice !== -1 &&
                            <ProductTag productId={product.productId} />
                        }
                        <div className="zoom-image-wrapper-base height-10">
                            {/* <img loading="lazy" alt="" className="each-item-bg-image" src={product.multimediaInfo.imageUrls[0]} /> */}
                            <img loading="lazy" alt="" className="each-item-image" src={product.multimediaInfo.imageUrls[0]} />
                        </div>
                        <p className="bold-font recommendation-item-name small">{product.name}</p>
                        <div className="recommendation-item-name-discount-div">
                            <div className="recommendation-item-prices">
                                {
                                    product.discountedPrice !== -1 ? (
                                        <>
                                            <p className="recommendation-item-name-discount-price">${product.discountedPrice}</p>
                                            <p className="recommendation-item-name-discount-old">${product.price}</p>
                                        </>
                                    ) : <p className="recommendation-item-name-discount-price">${product.price}</p>
                                }
                            </div>
                            {
                                product.discountedPrice !== -1 &&
                                <p className="recommendation-item-name-discount-percent">-{calculateDiscountPercentage(product?.price, product?.discountedPrice)}%</p>
                            }
                        </div>
                        <button className="recommendation-item-button small">See more</button>
                    </div>
                ))
            }
        </div>
    )
}