import React from "react";
import logo from "../images/logo.png"

export default function Logo() {
    return (
        <div className="logo-div">
            <img alt="logo" className="logo" src={logo} />
            <p className="logo-text">Viral <span className="logo-subtext">Treasures</span></p>
        </div>
    )
}