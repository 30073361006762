import React from "react";

export default function Stars({ rate }) {
    return (
        <div className="stars-div">
            {
                Array.from({ length: 5 }, (_, i) => i + 1).map((i) => {
                    let width = 0;
                    if (i <= rate)
                        width = 100;
                    else if (rate + 1 > i) {
                        width = (rate * 100) % 100;
                    }

                    return (
                        <i key={i} className="fas fa-star"
                            style={{
                                background: `linear-gradient(90deg, var(--BLACK) ${width}%, #aaa 0%)`,
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            }}
                        />
                    )
                })
            }
        </div>
    )
}