import React from "react"

export default function NumberPicker({ value, onValueChanged }) {
    return (
        <div className="number-picker-container">
            <p className="number-picker-btn" onClick={() => onValueChanged(-1)}>-</p>
            <input onChange={(e) => onValueChanged(e.target.value - value)} value={value} type="number" />
            <p className="number-picker-btn" onClick={() => onValueChanged(1)}>+</p>
        </div>
    )
}