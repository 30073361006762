import React, { useEffect, useState } from "react";
import useApiGet from "../hooks/useApiGet";
import Loader from "../components/Loader";
import '../styles/purchases.css'

export default function PurchasesPage() {

    const [status, setStatus] = useState(0)
    const [data, , isLoading] = useApiGet(`purchases?status=${status}&sortBy=updateStatusTime&ascending=true`, false)
    const [purchases, setPurchases] = useState()

    const handleUpdatePurchase = (updatedPurchase) => {
        setPurchases([...purchases].filter(purchase => purchase.purchaseId !== updatedPurchase.purchaseId))
    }


    useEffect(() => {
        setPurchases(data)
    }, [data])

    return (
        <div className="center-container">
            <div className="purchases-page-container">
                <div className="purchases-status">
                    <button className={`status-button ${status === 0 ? 'active' : ''}`} onClick={() => setStatus(0)}>Paid</button>
                    <button className={`status-button ${status === 1 ? 'active' : ''}`} onClick={() => setStatus(1)}>Ordered</button>
                    <button className={`status-button ${status === 2 ? 'active' : ''}`} onClick={() => setStatus(2)}>Delivered</button>
                    <button className={`status-button ${status === 3 ? 'active' : ''}`} onClick={() => setStatus(3)}>Returned</button>
                </div>

                {isLoading || purchases === null ? (
                    <div className="loader-div">
                        <Loader />
                    </div>
                ) : (
                    <div className="table-margin">
                        {purchases.length > 0 ? (
                            <div className="table-div">
                                <table class="rwd-table">
                                    <tbody>
                                        <tr>
                                            <th>Product ID</th>
                                            <th>Amount</th>
                                            <th>Quantity</th>
                                            <th>Aliexpress</th>
                                            {status !== 0 && <th>Confirmation</th>}
                                            <th>Payment</th>
                                            <th>Address</th>
                                            <th>Last update</th>
                                            {status !== 3 && <th>Change to</th>}
                                        </tr>
                                        {
                                            purchases.map((purchase) =>
                                                <PurchaseRow
                                                    key={purchase.purchaseId}
                                                    status={status}
                                                    onUpdatePurchases={handleUpdatePurchase}
                                                    purchase={purchase} />)
                                        }

                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No records found</p>
                        )
                        }
                    </div>
                )
                }

            </div>
        </div >
    );
}

function PurchaseRow({ status, purchase, onUpdatePurchases }) {

    const [isConfirming, setIsConfirming] = useState(false)

    const [confirmLink, setConfirmLink] = useState('')

    const handleUpdatePurchase = async (status) => {
        if (confirmLink.length > 0 || status > 1) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        purchaseId: purchase.purchaseId,
                        status: status,
                        confirmationLink: confirmLink
                    })
            };
            try {
                let response = await fetch('/api/purchases', requestOptions);
                if (response.ok) {
                    onUpdatePurchases(purchase)
                    setConfirmLink('')
                }

            } catch (ex) {

            }
        }
    }

    return (
        <tr>
            {
                !isConfirming &&
                <>
                    <td data-th="Product ID">
                        {purchase.productId}
                    </td>
                    <td data-th="Amount">
                        ${purchase.amount}
                    </td>
                    <td data-th="Quantity">
                        {purchase.quantity}
                    </td>
                    <td data-th="Aliexpress">
                        <a target="_blank" rel="noreferrer" href={`https://www.aliexpress.us/item/${purchase.aliexpressProductId}.html`}>Link <i className="fas fa-external-link-alt" /></a>
                    </td>
                    <td data-th="Stripe">
                        <a target="_blank" rel="noreferrer" href={`https://dashboard.stripe.com/payments/${purchase.stripePaymentId}`}>Link <i className="fas fa-external-link-alt" /></a>
                    </td>
                    {
                        status > 0 &&
                        <td data-th="Confirmation">
                            <a target="_blank" rel="noreferrer" href={purchase.confirmationLink}>Link <i className="fas fa-external-link-alt" /></a>
                        </td>
                    }
                    <td data-th="Address">
                        {JSON.stringify(purchase.address)}
                    </td>
                    <td data-th="Last update">
                        {new Date(purchase.updateStatusTime).toLocaleDateString('en-us', { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric" })}
                    </td>
                    {status !== 3 &&
                        <td data-th="Change to">
                            {status === 0 &&
                                <button onClick={() => setIsConfirming(true)}>Ordered</button>
                            }
                            {status === 1 &&
                                <button onClick={() => setIsConfirming(true)}>Delivered</button>
                            }
                            {status === 2 &&
                                <button onClick={() => setIsConfirming(true)}>Returned</button>
                            }
                        </td>
                    }
                </>
            }

            {
                isConfirming &&
                <>
                    {status === 0 ? (
                        <td colSpan={6} className="confirming-td">
                            <input onChange={(e) => setConfirmLink(e.target.value)} placeholder="Paste the aliexpress purchase link" className="confirming-input" type="text" />
                        </td>) :
                        (
                            <td colSpan={7} className="confirming-td">
                                <p>Are you sure you want to do it?</p>
                            </td>
                        )
                    }

                    <td colSpan={2} className="confirming-buttons">
                        <div className="confirming-buttons-div">
                            <button onClick={() => handleUpdatePurchase(status + 1)}>Confirm</button>
                            <button onClick={() => setIsConfirming(false)} className="confirming-cancel">Cancel</button>
                        </div>
                    </td>
                </>
            }

        </tr>
    )
}