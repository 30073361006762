export async function subscribeToNewsletter(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };
    try {
        const API_ENDPOINT = process.env.REACT_APP_VIRAL_TREASURES_API_ENDPOINT;
        let response = await fetch(`${API_ENDPOINT}/api/mail/newsletter`, requestOptions); if (response.ok)
            return true
        else return false
    } catch (ex) {
        return false
    }
}