import { useEffect, useState } from "react";

export default function useSticky() {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        let isStick = false;
        const navbar = document.getElementById("navbar");
        const handleScroll = () => {

            let scroll = document.documentElement.scrollTop;
            if (!isStick && scroll > navbar.offsetHeight * 1.5) {
                startStickyTransition();
                isStick = true;
            }
            else if (scroll === 0) {
                setIsSticky(false)
                isStick = false;
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    function startStickyTransition() {
        setIsSticky(true);
    }

    return isSticky;
}