import React from "react";
import "../styles/homePage.css"
import useCountdown from "../hooks/useCountdown";

export default function CountdownBoxes({ includeDays = false, additionalDays = 0 }) {
    const countdown = useCountdown(2)
    return (
        <div className="countdown-div">
            {
                includeDays &&
                (
                    <><p className="countdown-p" >{countdown.days}</p> :</>
                )}
            <p className="countdown-p" >{countdown.hours}</p> :
            <p className="countdown-p" >{countdown.minutes}</p> :
            <p className="countdown-p" >{countdown.seconds}</p>
        </div>
    )
}