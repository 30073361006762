import { useEffect, useState } from "react";

const useApiGet = (url, cache = true) => {
    const API_ENDPOINT = process.env.REACT_APP_VIRAL_TREASURES_API_ENDPOINT;
    const ENV = process.env.NODE_ENV;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        const abortController = new AbortController();

        const fetchData = async () => {
            try {
                setIsLoading(true);
                const cachedData = sessionStorage.getItem(url);
                if (cachedData && cache && ENV === "production") {
                    setData(JSON.parse(cachedData));
                } else {
                    const response = await fetch(`${API_ENDPOINT}/api/${url}`, { signal: abortController.signal });
                    const responseData = await response.json();
                    sessionStorage.setItem(url, JSON.stringify(responseData));
                    setData(responseData);
                }

                setIsLoading(false);
            } catch (ex) {
                setIsLoading(false);
                setData(null);
                setError(ex.message);
            }
        };

        fetchData();

        return () => abortController.abort();
    }, [url, API_ENDPOINT, cache]);

    return [data, error, isLoading];
};

export default useApiGet;
