import React from "react"

export default function ShippingInfo({ product }) {
    return (
        <div className="shipping-info-div">
            <div className="shipping-info-content-div">
                <p>Pricing: </p>
                <p className="bold-font" >Free</p>
            </div>
            <div className="shipping-info-content-div">
                <p>Delivery Time: </p>
                <p className="bold-font" >{product?.logisticInfo?.deliveryTime} days</p>
            </div>
            {product?.logisticInfo?.grossWeight > 0 &&
                <div className="shipping-info-content-div">
                    <p>Product weight: </p>
                    <p className="bold-font" >{product?.logisticInfo?.grossWeight}</p>
                </div>
            }
            {product?.logisticInfo?.packageWidth > 0 &&
                <div className="shipping-info-content-div">
                    <p>Package dimension: </p>
                    <p className="bold-font" >
                        {`${product?.logisticInfo?.packageWidth}
                    x ${product?.logisticInfo?.packageHeight}
                    x ${product?.logisticInfo?.packageLength}`}
                    </p>
                </div>
            }
        </div>
    )
}