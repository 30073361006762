import React, { useEffect } from "react";
import "../styles/recommendations.css"
import ProductTag from "./ProductTag";
import useApiGet from "../hooks/useApiGet";
import calculateDiscountPercentage from "../utilities/calculateDiscountPercentage";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";

export default function HorizontalRecommendations({ id, onLoaded }) {

    const [products, , isLoading] = useApiGet("products/recommended?limit=7")
    const navigate = useNavigate()

    useEffect(() => {
        if (products != null && onLoaded != null)
            onLoaded()
        // eslint-disable-next-line
    }, [products])

    if ((isLoading || products === null) && onLoaded === null)
        return (
            <Loader />
        )

    return (
        <div id={id} className="recommendation-div">
            {
                products?.map((product) => (
                    <div onClick={() => navigate(`../product/${product.productId}`)} key={product.productId} className="recommendation-item">
                        {product.discountedPrice !== -1 &&
                            <ProductTag productId={product.productId} />
                        }
                        <div className="zoom-image-wrapper-base width-14 height-19">
                            {/* <img loading="lazy" alt="" className="each-item-bg-image" src={product.multimediaInfo.imageUrls[0]} /> */}
                            <img loading="lazy" alt="" className="each-item-image" src={product.multimediaInfo.imageUrls[0]} />
                        </div>
                        <p className="bold-font recommendation-item-name">{product.name}</p>
                        <div className="recommendation-item-name-discount-div">
                            <div className="recommendation-item-prices">
                                {
                                    product.discountedPrice !== -1 ? (
                                        <>
                                            <p className="recommendation-item-name-discount-price">${product.discountedPrice}</p>
                                            <p className="recommendation-item-name-discount-old">${product.price}</p>
                                        </>
                                    ) : <p className="recommendation-item-name-discount-price">${product.price}</p>
                                }
                            </div>
                            {
                                product.discountedPrice !== -1 &&
                                <p className="recommendation-item-name-discount-percent">-{calculateDiscountPercentage(product?.price, product?.discountedPrice)}%</p>
                            }
                        </div>
                        <button className="recommendation-item-button">Check now</button>
                    </div>
                ))
            }
        </div>
    )
}