import React from "react"

export default function Done() {
    return (
        <div className="stripe-div message">
            <i className="fas fa-check"></i>
            <p className="bold-font">Thank you for your payment</p>
            <p>Your order has been successfully placed!</p>
        </div>
    )
}