import React from "react";

const tags = ["Bestseller", "New item", "Best price", "Limited edition", "Hot deal", "Discount"];

export default function ProductTag({ productId }) {

    let tag = sessionStorage.getItem(productId + "tag");
    if (!tag) {
        const randomIndex = Math.floor(Math.random() * tags.length);
        tag = tags[randomIndex];
        sessionStorage.setItem(productId + "tag", tag)
    }


    return <div className="tag">{tag}</div>;

}