import React, { useState } from "react"
import NumberPicker from '../components/NumberPicker'
import PaymentController from '../paymentProcessComponents/PaymentController'

export default function ExpandablePurchase({
    onSuccessfullPurchase,
    clickedPaymentCount,
    product,
    selectedSku,
    paymentSuccess }) {
    const [quantity, setQuantity] = useState(1)

    const handleQuantityChanged = (inc) =>
        setQuantity(quantity + inc < 1 ? 1 : quantity + inc)

    return (
        <div className="expandable-info-div">
            <div id="purchase-header" className="expandable-purchase-header">
                <NumberPicker
                    value={quantity}
                    onValueChanged={handleQuantityChanged} />
                <p className="checkout-steps bold-font">Checkout steps</p>
            </div>
            {
                <div className="expandable-info-content" >
                    <PaymentController
                        onSuccessfullPurchase={onSuccessfullPurchase}
                        clickedPaymentCount={clickedPaymentCount}
                        product={product}
                        selectedSku={selectedSku}
                        quantity={quantity}
                        paymentSuccess={paymentSuccess} />
                </div>
            }
        </div>
    )
}