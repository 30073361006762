import React, { useEffect, useState } from "react";
import "../styles/shopAll.css";
import useSticky from "../hooks/useSticky";
import Loader from "../components/Loader";
import ProductTag from "../components/ProductTag";
import useApiGet from "../hooks/useApiGet";
import calculateDiscountPercentage from "../utilities/calculateDiscountPercentage";
import { useNavigate } from "react-router-dom";

export default function ShopAll() {

    const [orderBy, setOrderBy] = useState('name')
    const [isAscending, setIsAscending] = useState(true)
    const [page, setPage] = useState(1)
    const [products, setProducts] = useState([])
    const [loadedProducts, , isLoading] = useApiGet(`products?page=${page}&count=12&orderBy=${orderBy}&ascending=${isAscending}`)
    const navigate = useNavigate()

    useEffect(() => {
        if (loadedProducts !== null)
            setProducts(products => [...products, ...loadedProducts]);
    }, [loadedProducts]);

    const handleLoadMore = () => {
        setPage(page + 1)
    }

    const handleSelectValue = (value) => {
        if (value == 0) {
            setOrderBy('name')
        }
        else if (value == 1) {
            setOrderBy('createdAt')
            setIsAscending(false)
        }
        else if (value == 2) {
            setOrderBy('price')
            setIsAscending(true)
        }
        else if (value == 3) {
            setOrderBy('price')
            setIsAscending(false)
        }
        setPage(1)
        setProducts([])
    }

    return (
        <div className="center-container">
            <div className="shop-all-page-div">

                <FilterHeader onValueSelect={handleSelectValue} />

                <div className="shop-all-gallery">
                    {
                        products?.map(product => (
                            <div key={product.productId} onClick={() => navigate(`../product/${product.productId}`)} className="recommendation-item shop-all">
                                {product.discountedPrice !== -1 &&
                                    <ProductTag productId={product.productId} />
                                }
                                <div className="zoom-image-wrapper-base height-14">
                                    <img loading="lazy" alt="" className="each-item-bg-image" src={product.multimediaInfo.imageUrls[0]} />
                                    <img loading="lazy" alt="" className="each-item-image" src={product.multimediaInfo.imageUrls[0]} />
                                </div>
                                <p className="bold-font recommendation-item-name">{product.name}</p>
                                <div className="recommendation-item-name-discount-div">
                                    <div className="recommendation-item-prices">
                                        {
                                            product.discountedPrice !== -1 ? (
                                                <>
                                                    <p className="recommendation-item-name-discount-price">${product.discountedPrice}</p>
                                                    <p className="recommendation-item-name-discount-old">${product.price}</p>
                                                </>
                                            ) : <p className="recommendation-item-name-discount-price">${product.price}</p>
                                        }
                                    </div>
                                    {
                                        product.discountedPrice !== -1 &&
                                        <p className="recommendation-item-name-discount-percent">-{calculateDiscountPercentage(product?.price, product?.discountedPrice)}%</p>
                                    }
                                </div>
                                <button onClick={() => navigate(`../product/${product.productId}`)} className="recommendation-item-button shop-all">Buy now</button>
                            </div>
                        ))
                    }
                </div>
                {
                    loadedProducts && loadedProducts.length > 0 &&
                    <div className="load-more-reviews">
                        {
                            isLoading ?
                                <Loader />
                                : <p className="load-more-text bold-font clickable" onClick={handleLoadMore}>Load more</p>
                        }
                    </div>
                }

            </div>
        </div>
    );
}


function FilterHeader({ onValueSelect }) {
    const isSticky = useSticky();

    return (
        <div className={`sticky-filters ${isSticky ? 'make-filters-sticky' : ''}`}>
            <div className="sort-by-div">
                <p className="bold-font">Sort by:</p>
                <select onChange={(e) => onValueSelect(e.target.value)}>
                    <option value={0}>Recommended</option>
                    <option value={1}>Newly released</option>
                    <option value={2}>Price, low to high</option>
                    <option value={3}>Price, high to low</option>
                </select>
            </div>
        </div>
    )
}
