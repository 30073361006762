import { useEffect, useState } from "react";

export default function useCountdown(additionalDays = 0) {
    const [countdown, setCountdown] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        function calculateTimeTillEndOfDay() {
            const now = new Date();
            const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + additionalDays, 23, 59, 59);
            const timeDiff = endOfDay.getTime() - now.getTime();
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor(timeDiff / (1000 * 60 * 60) % 24);
            const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);
            const seconds = Math.floor((timeDiff / 1000) % 60);
            return {
                days: days < 10 ? "0" + days : days,
                hours: hours < 10 ? "0" + hours : hours,
                minutes: minutes < 10 ? "0" + minutes : minutes,
                seconds: seconds < 10 ? "0" + seconds : seconds,
            };
        }

        setCountdown(calculateTimeTillEndOfDay());

        const interval = setInterval(() => {
            setCountdown(calculateTimeTillEndOfDay());
        }, 1000);

        return () => clearInterval(interval);
    }, [additionalDays]);

    return countdown;
};