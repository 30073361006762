import ProductPage from "./pageComponents/ProductPage";
import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pageComponents/HomePage";
import ContactPage from "./pageComponents/ContactPage";
import PrivacyPolicyPage from "./pageComponents/PrivacyPolicyPage";
import TermsOfServicePage from "./pageComponents/TermsOfServicePage";
import RefundPolicyPage from "./pageComponents/RefundPolicyPage";
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ShopAll from "./pageComponents/ShopAll";
import PurchasesPage from "./pageComponents/PurchasesPage";
import ReactGA from 'react-ga';

export default function App() {

  ReactGA.initialize("G-ESWCYZT940");
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      <NavigationBar />
      <Routes>
        <Route path="/*" element={<HomePage />} />
        <Route path="/purchases" element={<PurchasesPage />} />
        <Route path="/shop-all" element={<ShopAll />} />
        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/refund-policy" element={<RefundPolicyPage />} />
      </Routes >
      <Footer />
    </>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0, 0);
  }, [pathname]);

  return null;
}

