import React from "react";

export default function Confirm({ quantity, product }) {
    return (
        <div className="stripe-div">
            <Order
                quantity={quantity}
                product={product} />
        </div>
    )
}

function Order({ quantity, product }) {
    const price = product.discountedPrice !== -1 ? product.discountedPrice : product.Price
    return (
        <>
            <p className="expandable-purchase-order">Order</p>
            <div className="expandable-purchase-total">
                <div>
                    <p>Subtotal</p>
                    <p>{quantity} x ${price}</p>
                </div>
                <div>
                    <p>Shipping</p>
                    <p>$0</p>
                </div>
                <hr />
                <div>
                    <p className="bold-font">Total</p>
                    <p className="bold-font">${quantity * price}</p>
                </div>
            </div>
        </>
    )
}