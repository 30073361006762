import React, { useEffect, useState } from "react";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";

export default function PurchaseFloatingButton({ productId, paymentSuccess, onButtonClick }) {
    const [isPurchaseButtonVisible, setIsPurchaseButtonVisible] = useState(false)
    const gaEventTracker = useAnalyticsEventTracker('Product page')

    useEffect(() => {
        let isBottomPurchaseVisible = false;
        const purchaseButton = document.getElementById("purchase-header");

        const handleScroll = () => {
            let scroll = document.documentElement.scrollTop;
            if (!isBottomPurchaseVisible && scroll > (purchaseButton.offsetTop + purchaseButton.clientHeight) * 0.5) {
                setIsPurchaseButtonVisible(true)
                isBottomPurchaseVisible = true;
            } else if (isBottomPurchaseVisible && scroll <= (purchaseButton.offsetTop + purchaseButton.clientHeight) * 0.5) {
                isBottomPurchaseVisible = false;
                setIsPurchaseButtonVisible(false)
            }

        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleButtonClick = () => {
        document.getElementById('purchase-steps').scrollIntoView({
            behavior: 'smooth'
        });
        onButtonClick();
        gaEventTracker("floating_buy_button_clicked", productId)
    }


    if (paymentSuccess)
        return <></>

    return (
        <div className={`purchase-div mobile-only ${isPurchaseButtonVisible ? 'active' : ''}`}>
            <button onClick={handleButtonClick}>Buy now</button>
        </div>
    )
}