import React, { useState, useEffect, useRef } from "react";
import "../styles/navigationBar.css"
import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import useSticky from "../hooks/useSticky";
import NewsletterSubscription from "./NewsletterSubscription";

export default function NavigationBar() {
    const isSticky = useSticky()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const location = useLocation();

    useEffect(() => {
        setIsDrawerVisible(false)
    }, [location]);

    useEffect(() => {
        if (isMenuOpen) {
            setTimeout(() => {
                setIsDrawerVisible(isMenuOpen)
            }, 100)
        }
    }, [isMenuOpen])

    useEffect(() => {
        if (!isDrawerVisible) {
            setTimeout(() => {
                setIsMenuOpen(false)
            }, 250)
        }
    }, [isDrawerVisible])

    const [promotionText, setPromotionText] = useState('Free shipping on all orders!')
    const promotionTexts = ['Free shipping on all orders!', 'Explore our exclusive collection', 'Dive into the Extraordinary!', 'Step into the World of Viral Wonders!', 'Uncover the latest viral trends in one place.']
    const currentPromotionText = useRef(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setPromotionText(promotionTexts[currentPromotionText.current])
            currentPromotionText.current = (currentPromotionText.current + 1) % (promotionTexts.length)
        }, 5000);
        return () => clearInterval(interval)
    }, [isSticky])

    return (
        <>
            <div>
                {!isSticky &&
                    <div className="promotion-div">
                        <p className="promotion-text" key={promotionText}> {promotionText}</p>
                    </div>
                }
                <div id="navbar" className={`sticky-container ${isSticky ? 'make-sticky' : ''}`} >
                    <div className="navigationbar-container">
                        <Link to="/home">
                            <Logo />
                        </Link>
                        <div className="desktop-links">
                            <ul>
                                <li> <Link to='/shop-all'>Shop all</Link></li>
                                <li> <Link to='/privacy-policy'>Privacy policy</Link></li>
                                <li> <Link to='/terms-of-service'>Terms of service</Link></li>
                                <li> <Link to='/refund-policy'>Refund policy</Link></li>
                                <li> <Link to='/contact'>Contact</Link></li>
                            </ul>
                        </div>
                        <i onClick={() => setIsMenuOpen(true)} className="fas fa-bars menu-icon"></i>
                    </div>
                </div>
                {isMenuOpen &&
                    <div className="drawer-shadow-div">
                        <div className={`drawer ${isDrawerVisible ? 'active' : ''}`}>
                            <div className="drawer-content">
                                <div onClick={() => setIsDrawerVisible(false)} className="drawer-close">
                                    <i className="fas fa-times"></i>
                                    <p>Close</p>
                                </div>
                                <Link to='/home'><p className="nav-drawer-item">Home</p></Link>
                                <Link to='/shop-all'><p className="nav-drawer-item">Shop all</p></Link>
                                <Link to='/privacy-policy'><p className="nav-drawer-item">Privacy policy</p></Link>
                                <Link to='/terms-of-service'><p className="nav-drawer-item">Terms of service</p></Link>
                                <Link to='/refund-policy'><p className="nav-drawer-item">Refund policy</p></Link>
                                <Link to='/contact'><p className="nav-drawer-item">Contact</p></Link>
                            </div>
                            <div className="drawer-footer">
                                <p className="align-text-center">Subscribe to get special offers and great deals!</p>
                                <NewsletterSubscription />
                                <div className="footer-social-media">
                                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61554017963913"><i className="fab fa-facebook"></i></a>
                                    <a><i className="fab fa-instagram"></i></a>
                                    <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@viraltreasures.store"><i className="fab fa-tiktok"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

