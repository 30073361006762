import React, { useEffect, useRef, useState } from "react";
import { Slide } from "react-slideshow-image";
import DotsIndicator from "../components/DotsIndicator";

export default function Gallery({ selectedSku, product, isDesktop }) {

    const [index, setIndex] = useState(0)
    const [slideKey, setSlideKey] = useState(Math.random());
    const [previousSelectedSku, setPreviousSelectedSku] = useState(null)
    const horizontalImagesRef = useRef(null);

    const images = product?.multimediaInfo?.imageUrls ?? []
    const skuImages = product?.sku?.items?.map(skuItem => skuItem.url) ?? []
    const allImages = [...images, ...skuImages]

    const arrows = {
        prevArrow: <button className="gallery-arrow-button gallery"><i className="fas fa-chevron-left" /></button>,
        nextArrow: <button className="gallery-arrow-button gallery"><i className="fas fa-chevron-right" /></button>
    }

    useEffect(() => {
        setSlideKey(Math.random());
    }, [product])

    useEffect(() => {
        if (selectedSku != null && previousSelectedSku != null) {
            setSlideKey(Math.random());
            setIndex(allImages.indexOf(selectedSku.url))
        }
        setPreviousSelectedSku(selectedSku)
    }, [selectedSku])

    useEffect(() => {
        let timeoutId;
        if (horizontalImagesRef.current) {
            const targetImage = horizontalImagesRef.current.querySelector(`#small-img-${index}`);
            if (targetImage) {
                targetImage.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                });
                timeoutId = setTimeout(() => {
                    targetImage.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'center',
                    });
                }, 500)
            }
        }
        return () => clearTimeout(timeoutId)
    }, [index])

    const handleSlideClick = (idx) => {
        setIndex(idx);
        setSlideKey(Math.random());
    };

    return (
        <div className="product-page-images">
            <div className="product-gallery-current-div">
                {allImages &&
                    <Slide
                        canSwipe={!isDesktop}
                        {...arrows}
                        key={slideKey}
                        onChange={(_, to) => setIndex(to)}
                        autoplay={false}
                        defaultIndex={index}
                        transitionDuration={0}>
                        {
                            allImages.map((img, idx) => (
                                <div key={idx} className="each-slide-effect">
                                    <img loading="lazy" alt="" className="each-slide-bg-image" src={img} />
                                    <img loading="lazy" alt="" className="each-slide-main-image" src={img} />
                                </div>
                            ))
                        }
                    </Slide>
                }
            </div>

            <DotsIndicator collection={allImages} index={index} />

            <div className="horizontal-images" ref={horizontalImagesRef}>
                {
                    allImages.map((img, idx) => (
                        <div key={idx}
                            onClick={() => handleSlideClick(idx)}
                            className="each-slide-effect">
                            <img loading="lazy" alt="" id={`small-img-${idx}`} className={`each-slide-main-image-small ${index === idx ? 'active' : ''}`} src={img} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
}