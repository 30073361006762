import React from "react"

export default function Failed({ message, onTryToPayAgain }) {
    return (
        <div className="stripe-div message">
            <i className="fas fa-times"></i>
            <p className="bold-font">Ups...</p>
            <p>{message}</p>
            <button className="make-payment-button" onClick={onTryToPayAgain}>Try to pay again</button>
        </div>
    )
}