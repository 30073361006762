import React, { useEffect } from "react";
import ProductTag from "./ProductTag";
import { useNavigate } from "react-router-dom";
import useApiGet from "../hooks/useApiGet";
import calculateDiscountPercentage from "../utilities/calculateDiscountPercentage";

export default function HorizontalSquareRecommendations({ id, onLoaded }) {

    const [products] = useApiGet("products/recommended?limit=9")
    const navigate = useNavigate()

    useEffect(() => {
        if (products != null && onLoaded != null)
            onLoaded()
        // eslint-disable-next-line
    }, [products])

    return (
        <div id={id} className="recommendation-div">
            {
                products?.map((product, idx) => (
                    <div onClick={() => navigate(`../product/${product.productId}`)} key={product.productId} className="recommendation-item width-10">
                        {product.discountedPrice !== -1 &&
                            <ProductTag productId={product.productId} />
                        }
                        <div className="zoom-image-wrapper-base width-10 height-10">
                            {/* <img loading="lazy" alt="" className="each-item-bg-image" src={product.multimediaInfo.imageUrls[0]} /> */}
                            <img loading="lazy" alt="" className="each-item-image" src={product.multimediaInfo.imageUrls[0]} />
                        </div>
                        <p className="bold-font recommendation-item-square-name">{product.name}</p>
                        <div className="recommendation-item-name-discount-div">
                            <div className="recommendation-item-prices">
                                {
                                    product.discountedPrice !== -1 ? (
                                        <>
                                            <p className="recommendation-item-name-discount-price">${product.discountedPrice}</p>
                                            <p className="recommendation-item-name-discount-old small">${product.price}</p>
                                        </>
                                    ) : <p className="recommendation-item-name-discount-price">${product.price}</p>
                                }
                            </div>
                            {
                                product.discountedPrice !== -1 &&
                                <p className="recommendation-item-name-discount-percent">-{calculateDiscountPercentage(product?.price, product?.discountedPrice)}%</p>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}