import { useState, useEffect } from "react";

export default function useAnimatedNumber(min, max) {

    const getRandomNumber = (min, max) => {
        return Math.round(Math.random() * (max - min) + min);
    }

    const [number, setNumber] = useState(getRandomNumber(min, max));

    useEffect(() => {

        const interval = setInterval(() => {
            setNumber(getRandomNumber(number - 20, number + 20));
        }, 5000);

        return () => clearInterval(interval);
    });

    return number
}