import React, { useState } from "react"

export default function ExpandableInfo({ title, content }) {

    const [isExpanded, setIsExpanded] = useState(true)

    return (
        <div className="expandable-info-div">
            <div
                onClick={() => setIsExpanded(!isExpanded)}
                className={`expandable-info-header title-of-recommendations ${title !== 'Description' ? 'remove-desktop-padding' : ''}`}>
                <p className="expandable-info-title">{title}</p>
                <i className={`fas expandable-icon fa-chevron-up ${isExpanded ? "expanded" : ''}`}></i>
            </div>
            {
                isExpanded &&
                <div className="expandable-info-content" >
                    {content}
                </div>
            }
        </div >
    )
}