import React from "react"
import calculateDiscountPercentage from "../utilities/calculateDiscountPercentage"
import CountdownBoxes from "../components/CountdownBoxes"
import Stars from "../components/Stars"

export default function GeneralInfo({ selectedSku, onSelectedSku, product }) {


    return (
        <div className="product-page-title-div remove-desktop-padding">
            <p className="product-name bold-font">{product?.name}</p>

            <div className="prices">
                <div className="old-new-price-div">
                    {
                        product?.discountedPrice !== -1 ? (
                            <>
                                <p className="price">${product?.discountedPrice} USD</p>
                                <p className="old-price">${product?.price}</p>
                            </>
                        ) : <p className="price">${product?.price} USD</p>
                    }

                </div>
                {product?.discountedPrice !== -1 &&
                    <p className="delivery recommendation-item-name-discount-percent">-{calculateDiscountPercentage(product?.price, product?.discountedPrice)}%</p>
                }
            </div>

            <div className="encouragement-div">
                <div className="countdown-display-div">
                    <p>{product?.discountedPrice !== -1 ? "Discount ends in" : "Offer expires in"}</p>
                    <CountdownBoxes />
                </div>
                <div className="reviews-and-sales-div">
                    <Stars rate={Number(product?.rating)} />
                    <p className="bold-font">{product?.ratingCount}</p>
                    {product?.salesCount &&
                        <>
                            <p>|</p>
                            <p>{product?.salesCount}+ sales</p>
                        </>
                    }
                </div>
            </div>

            <SkuImages
                selectedSku={selectedSku}
                onSelectedSku={onSelectedSku}
                product={product} />

        </div>
    )
}

function SkuImages({ onSelectedSku, selectedSku, product }) {
    const skuImages = product?.sku?.items ?? []

    return (
        <div>
            <p>{selectedSku?.name}</p>
            <div className="sku-items">
                {
                    skuImages.map((skuItem, idx) => (
                        <div key={idx}
                            className="each-slide-effect">
                            <img onClick={() => onSelectedSku(skuItem)} loading="lazy" alt="" className={`each-sku-image  ${selectedSku === skuItem ? 'active' : ''}`} src={skuItem.url} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}