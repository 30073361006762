import React from "react"

export default function PurchaseSteps({ stage }) {
    return (
        <div id="purchase-steps" className="purchase-steps-div">
            <div className="purchase-step">
                <div className={`purchase-step-circle ${stage > 0 ? 'active-step' : ''} ${stage > 1 ? 'completed-step' : ''} `}>
                    <p>1</p>
                </div>
                <p>Confirm</p>
            </div>

            <div className={`purchase-separator ${stage > 0 ? 'active-step' : ''}`} />

            <div className="purchase-step">
                <div className={`purchase-step-circle ${stage > 1 ? 'active-step' : ''} ${stage > 2 ? 'completed-step' : ''}`}>
                    <p>2</p>
                </div>
                <p>Ship & Pay</p>
            </div>

            <div className={`purchase-separator ${stage > 2 ? 'active-step' : ''}`} />

            <div className="purchase-step">
                <div className={`purchase-step-circle ${stage > 2 ? 'active-step completed-step' : ''} `}>
                    <p>3</p>
                </div>
                <p>Done</p>
            </div>
        </div>
    )
}