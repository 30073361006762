import React, { useState } from "react";
import "../styles/homePage.css"
import HorizontalRecommendations from "../components/HorizontalRecommendations";
import HorizontalSquareRecommendations from "../components/HorizontalSquareRecommendations";
import VerticalRecommendations from "../components/VerticalRecommendations";
import CoundtownBoxes from "../components/CountdownBoxes";
import AnimatedNumber from "react-animated-numbers";
import useAnimatedNumber from "../hooks/useAnimatedNumber";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import bgImage from "../images/promoImage.jpg"


export default function HomePage() {

    const navigate = useNavigate()

    const scrollHorizontally = (id, left) => {
        var doc = document.getElementById(id);
        doc.scrollLeft += doc.clientWidth * (left ? -1 : 1);
    }

    const [loadedRecommendations, setLoadedRecommendations] = useState([false, false, false])


    return (
        <div className="center-container">
            <div className="opening-image-div">
                <div>
                    <img className="opening-image" src={bgImage} />
                </div>
                <div className="opening-image-texts">
                    <div className="lineUp">
                        <p className="bold-font">Discover Viral's Best</p>
                        <p>Shop Viral Treasures</p>
                    </div>
                    <button onClick={() => navigate('/shop-all')} className="shop-now-button">Shop now</button>
                </div>
            </div>

            <div className="home-page-container">
                <CurrentPromos />
                {

                    <div className="home-page-content">
                        {
                            loadedRecommendations[0] &&
                            <div className="lightning-deals-div section-title">
                                <p>Special offers</p>
                                <div className="countdown-display-div">
                                    <p>Ends in</p> <CoundtownBoxes additionalDays={3} includeDays />
                                </div>
                            </div>
                        }

                        {
                            loadedRecommendations[0] === false &&
                            <div className="home-page-loader">
                                <Loader />
                            </div>
                        }

                        <HorizontalSquareRecommendations
                            onLoaded={() => setLoadedRecommendations(recommendations => [true, recommendations[1], recommendations[2]])}
                            id="horizontal-recommendations1" />

                        {loadedRecommendations[0] &&
                            <div className="desktop-only">
                                <ClickForMore id="horizontal-recommendations1" scrollHorizontally={scrollHorizontally} />
                            </div>
                        }
                    </div>
                }

                <div className="home-page-content">
                    {loadedRecommendations[0] && loadedRecommendations[1] &&
                        <p className="section-title recommendations">You might like</p>
                    }
                    {
                        loadedRecommendations[0] && loadedRecommendations[1] === false &&
                        <div className="home-page-loader">
                            <Loader />
                        </div>
                    }

                    <HorizontalRecommendations
                        onLoaded={() => setLoadedRecommendations(recommendations => [recommendations[0], true, recommendations[2]])}
                        id="horizontal-recommendations2" />
                    {loadedRecommendations[0] && loadedRecommendations[1] &&
                        <ClickForMore id="horizontal-recommendations2" scrollHorizontally={scrollHorizontally} />
                    }
                </div>


                <div className="home-page-content">
                    {loadedRecommendations[0] && loadedRecommendations[1] && loadedRecommendations[2] &&
                        <p className="section-title recommendations">Bestsellers</p>
                    }
                    {loadedRecommendations[0] && loadedRecommendations[1] && loadedRecommendations[2] === false &&
                        <div className="home-page-loader">
                            <Loader />
                        </div>
                    }
                    <VerticalRecommendations
                        onLoaded={() => setLoadedRecommendations(recommendations => [recommendations[0], recommendations[1], true])} />
                    {loadedRecommendations[0] && loadedRecommendations[1] && loadedRecommendations[2] &&
                        <div className="click-for-more-div">
                            <button onClick={() => navigate('/shop-all')} className="click-for-more-button">Shop all items</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

function ClickForMore({ id, scrollHorizontally }) {
    const navigate = useNavigate()
    return (
        <div className="click-for-more-div">
            <button onClick={() => navigate('/shop-all')} className="click-for-more-button">Click for more</button>
            <div className="click-for-more-arrows">
                <button onClick={() => scrollHorizontally(id, true)} className="click-for-more-arrow-button"><i className="fas fa-chevron-left" /></button>
                <button onClick={() => scrollHorizontally(id, false)} className="click-for-more-arrow-button"><i className="fas fa-chevron-right" /></button>
            </div>
        </div>
    )
}

function CurrentPromos() {
    const numberOfVisitors = useAnimatedNumber(200, 3000)

    return (
        <>
            <div className="home-page-content">
                <div className="current-promos mobile-promos">
                    <div className="current-promos-div">
                        <div className="current-promos-header">
                            <p className="free-shipping">Lightning deals</p>
                        </div>
                        <div className="countdown-display-div current-promos-content">
                            <p className="countdown-time-left-margin">Time left</p> <CoundtownBoxes />
                        </div>
                    </div>
                    <div className="current-promos-separator" />
                    <div className="current-promos-div">
                        <div className="current-promos-header">
                            <p className="free-shipping">Active users</p>
                        </div>
                        <div className="current-promos-content">
                            <div className="number-of-people">
                                <AnimatedNumber
                                    includeComma
                                    animateToNumber={numberOfVisitors}
                                    fontStyle={{ fontSize: '1.3rem', fontFamily: 'Montserrat' }}
                                    locale="en-US" />
                                <p>people</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home-page-content desktop-promos">
                <div className="current-promos-desktop">
                    <div className="current-promos-desktop-div">
                        <p className="free-shipping bold-font">Lightning deals:</p>
                        <CoundtownBoxes />
                    </div>
                    <div className="current-promos-desktop-div">
                        <p className="free-shipping bold-font">Active users:</p>
                        <AnimatedNumber
                            includeComma
                            animateToNumber={numberOfVisitors}
                            fontStyle={{ fontSize: '1.3rem', fontFamily: 'Montserrat' }}
                            locale="en-US" />
                        <p>people</p>
                    </div>

                    <div className="current-promos-desktop-div safe-payments">
                        <i className="fas fa-shield-alt"></i>
                        <p className="free-shipping bold-font">Safe payments</p>
                    </div>
                </div>
            </div>
        </>
    )
}