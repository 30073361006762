import React from "react"

export default function DotsIndicator({ collection, index }) {
    return (
        <div className="collecton-dots">
            {collection.map((_, idx) => (
                <div key={idx} className={`collection-dot ${idx === index ? 'active-dot' : ''}`} />
            ))}
        </div>
    )
}