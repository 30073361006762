import { useEffect, useState } from "react";
import useApiGet from "../hooks/useApiGet";
import Stars from "../components/Stars";
import Zoom from 'react-medium-image-zoom'
import Loader from "../components/Loader";

export default function ExpadableReviews({ product }) {
    const [isExpanded, setIsExpanded] = useState(true)
    const [page, setPage] = useState(1)

    const [loadedReviews, , isLoading] = useApiGet(`products/reviews?productId=${product?.aliexpressProductId}&ownerMemberId=${product?.aliexpressOwnerId}&page=${page}`)

    useEffect(() => {
        if (loadedReviews !== null)
            setReviews(prevReviews => [...prevReviews, ...loadedReviews]);
    }, [loadedReviews])

    const [reviews, setReviews] = useState([])

    const handleLoadMore = () => {
        setPage(page + 1)
    }
    return (
        <div className="expandable-info-div">
            <div onClick={() => setIsExpanded(!isExpanded)} className={`expandable-info-header title-of-recommendations remove-desktop-padding }`}>
                <div>
                    <p className="expandable-info-title">Item Reviews</p>
                    <div className="reviews-and-sales-div">
                        {product &&
                            <>
                                <p className="bold-font">{product?.ratingCount} reviews</p>
                                <p>|</p>
                                <Stars rate={Number(product?.rating)} />
                            </>
                        }
                    </div>
                </div>
                <i className={`fas expandable-icon fa-chevron-up ${isExpanded ? "expanded" : ''}`}></i>
            </div>
            {
                isExpanded &&
                < div >
                    <div className="expandable-info-content" >
                        {
                            reviews?.map((review, idx) => (
                                <div key={idx} className="review-div">
                                    <p>{review.name}</p>
                                    <div className="review-div-rating-and-date">
                                        <Stars rate={review.rating} />
                                        <p className="review-date">{(new Date(review.date)).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                                    </div>
                                    <div className="review-images">
                                        {
                                            review?.photos?.map((img, idx) => (
                                                <Zoom key={idx} classDialog="custom-zoom">
                                                    <img loading="lazy" alt="" className="review-images-img" src={img} />
                                                </Zoom>
                                            ))
                                        }
                                    </div>
                                    <p>{review.content}</p>
                                </div>
                            ))
                        }
                    </div>
                    {
                        loadedReviews && loadedReviews.length > 0 && (product?.ratingCount === null || loadedReviews.length < product?.ratingCount) &&
                        <div className="load-more-reviews">
                            {
                                isLoading ?
                                    <Loader />
                                    : <p className="load-more-text bold-font clickable" onClick={handleLoadMore}>Load more</p>
                            }
                        </div>
                    }

                </div>
            }
        </div >
    )
}
