import React, { useEffect, useState } from "react"
import PurchaseSteps from "./PurchaseSteps"
import Confirm from "./Confirm"
import Done from "./Done"
import PaymentContainer from "./Payment"
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker"

export default function PaymentController({
    selectedSku,
    onSuccessfullPurchase,
    clickedPaymentCount,
    product,
    quantity,
    paymentSuccess }) {

    const [stage, setStage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [refreshPayment, setRefreshPayment] = useState(0)

    const amountToStripe = Math.floor((product.discountedPrice !== -1 ? product.discountedPrice : product.Price) * quantity * 100)
    const gaEventTracker = useAnalyticsEventTracker('Product page')

    useEffect(() => {
        setRefreshPayment(0)
    }, [quantity, selectedSku])

    useEffect(() => {
        if (stage == 1 && clickedPaymentCount > 0)
            setStage(2)
        else if (stage == 2) {
            setRefreshPayment(refreshPayment + 1)
        }
    }, [clickedPaymentCount])

    useEffect(() => {
        if (paymentSuccess === true)
            setStage(3)
        else if (paymentSuccess != null)
            setStage(2)
    }, [paymentSuccess])

    const handleStageChange = (newStage, next = false) => {
        if (isLoading)
            return;

        if (stage > newStage || next) {
            setStage(newStage)
        }
    }

    const handlePayment = (isLoading, success) => {
        setIsLoading(isLoading)

        if (success) {
            setStage(3)
            onSuccessfullPurchase()
        }
    }

    const handleConfirmClick = () => {
        handleStageChange(stage + 1, true)
        gaEventTracker("Confirm_button_clicked", product.id)
    }

    return (
        <>
            <PurchaseSteps
                stage={stage} />

            {stage == 1 &&
                <div className="stage-div">
                    <Confirm
                        quantity={quantity}
                        product={product} />
                    <button className="make-payment-button" onClick={handleConfirmClick}><i className="fas fa-shopping-cart" /><p>Checkout</p></button>
                </div>
            }

            {stage == 2 &&
                <div className="stage-div">
                    <PaymentContainer
                        amountToStripe={amountToStripe}
                        product={product}
                        paymentSuccess={paymentSuccess}
                        refreshPayment={refreshPayment}
                        onPayment={handlePayment}
                        selectedSku={selectedSku}
                        quantity={quantity} />
                </div>
            }

            {stage === 3 &&
                <div className="stage-div display-success">
                    <Done />
                </div>
            }

        </>
    )
}