import React, { useState, useEffect } from "react";
import "../styles/productPage.css"
import 'react-slideshow-image/dist/styles.css'
import HorizontalRecommendations from "../components/HorizontalRecommendations";
import 'react-medium-image-zoom/dist/styles.css'
import Loader from "../components/Loader";
import useApiGet from "../hooks/useApiGet";
import { useParams } from "react-router-dom";
import ExpandablePurchase from "../productPageComponents/ExpandablePurchase";
import Gallery from "../productPageComponents/Gallery";
import GeneralInfo from "../productPageComponents/GeneralInfo";
import ExpandableInfo from "../productPageComponents/ExpandableInfo";
import ExpendableTiktoks from "../productPageComponents/ExpandableTikToks";
import ShippingInfo from "../productPageComponents/ShippingInfo";
import ExpadableReviews from "../productPageComponents/ExpandableReviews";
import PurchaseFloatingButton from "../productPageComponents/PurchaseFloatingButton";

export default function ProductPage() {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 710);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    const [clickedPaymentCount, setClickedPaymentCount] = useState(0)
    const [selectedSku, setSelectedSku] = useState(null)
    const { productId } = useParams();
    const [product, , isLoading] = useApiGet(`products/${productId}`)

    useEffect(() => {
        setPaymentSuccess(null)
        setClickedPaymentCount(0)
        setSelectedSku(null)
    }, [productId])

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth > 700);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const urlString = window.location.href;
        if (urlString.includes("redirect_status")) {
            const urlParams = new URLSearchParams(urlString);
            const redirectStatus = urlParams.getAll("redirect_status");
            setPaymentSuccess(redirectStatus[redirectStatus.length - 1] === "succeeded")
        }
    }, [])


    useEffect(() => {
        if (product != null) {
            setSelectedSku(product?.sku?.items[0]);
        }
    }, [product])

    const handleSkuSelected = (sku) => {
        setSelectedSku(sku)
    }

    if (isLoading || product === null)
        return (
            <div className="loader-div">
                <Loader />
            </div>)

    return (
        <div className="center-container">
            <div className="product-page-container">
                <div className="desktop-column">
                    <Gallery selectedSku={selectedSku} isDesktop={isDesktop} product={product} />
                    {!isDesktop &&
                        <div>
                            <GeneralInfo onSelectedSku={handleSkuSelected} selectedSku={selectedSku} product={product} />
                            <ExpandablePurchase
                                onSuccessfullPurchase={() => setPaymentSuccess(true)}
                                clickedPaymentCount={clickedPaymentCount}
                                product={product}
                                selectedSku={selectedSku}
                                paymentSuccess={paymentSuccess} />
                        </div>
                    }

                    <ExpandableInfo
                        title="Description"
                        content={<div className="product-description" dangerouslySetInnerHTML={{ __html: product.jsxDetail }} />} />
                </div>

                <div className="desktop-column">
                    {isDesktop &&
                        <div>
                            <GeneralInfo selectedSku={selectedSku} onSelectedSku={handleSkuSelected} product={product} />
                            <ExpandablePurchase
                                onSuccessfullPurchase={() => setPaymentSuccess(true)}
                                product={product}
                                paymentSuccess={paymentSuccess} />
                        </div>
                    }
                    {/* <ExpendableTiktoks
                        product={product}
                        isDesktop={isDesktop} /> */}
                    <ExpandableInfo
                        title="Shipping info"
                        content={<ShippingInfo product={product} />} />
                    <ExpadableReviews product={product} />
                </div>

                <div className="desktop-two-columns">
                    <ExpandableInfo
                        title="You might also like"
                        content={<HorizontalRecommendations />} />
                </div>

                <div className="mobile-only">
                    <PurchaseFloatingButton
                        productId={productId}
                        paymentSuccess={paymentSuccess}
                        onButtonClick={() => { setClickedPaymentCount(clickedPaymentCount + 1); }} />
                </div>

            </div >
        </div >
    )
}

