import React, { useState } from "react";

export default function ContactPage() {

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        details: '',
    })

    const [attachement, setAttachement] = useState(null)
    const [successfullSubmit, setSuccessfullSubmit] = useState(null)

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData();
        formData.append('file', attachement)
        formData.append('json', JSON.stringify(form))
        const requestOptions = {
            method: 'POST',
            body: formData
        };

        try {
            const API_ENDPOINT = process.env.REACT_APP_VIRAL_TREASURES_API_ENDPOINT;
            let response = await fetch(`${API_ENDPOINT}/mail/contact`, requestOptions); if (response.ok)
                setSuccessfullSubmit(true)
            else setSuccessfullSubmit(false)
        } catch (ex) {
            setSuccessfullSubmit(false)
        }
    }

    return (
        <div className="supplementary-page-container">
            {
                successfullSubmit === null ? (
                    <form onSubmit={handleSubmit}>
                        <p className="bold-font page-title">Enquiry Form</p>
                        <div className="input-div">
                            <label for="firstName">First Name</label>
                            <input name="firstName" required onChange={handleChange} value={form.firstName} id="firstName" type="text" placeholder="Your first name..." />
                        </div>
                        <div className="input-div">
                            <label for="lastName">Last Name</label>
                            <input name="lastName" required onChange={handleChange} value={form.lastName} id="lastName" type="text" placeholder="Your last name..." />
                        </div>
                        <div className="input-div">
                            <label for="email" >Email</label>
                            <input name="email" required onChange={handleChange} value={form.email} id="email" type="email" placeholder="Your email..." />
                        </div>

                        <div className="input-div">
                            <label for="details" >Details</label>
                            <textarea name="details" required onChange={handleChange} value={form.details} id="details" placeholder="Please elaborate your inquiry here. If the inquiry concerns an order, please include the order number from the order confirmation." />
                        </div>

                        <div className="input-div">
                            <label for="file">File</label>
                            <input type="file" onChange={(e) => setAttachement(e.target.files[0])} accept="image/png, image/jpeg, image/jpeg, .pdf" />
                            <p className="subtext">Please use one of this file types: jpg, jpeg, png, pdf</p>
                        </div>

                        <button className="submit-button">Submit</button>
                    </form>



                ) : successfullSubmit ? (
                    <div className="contact-response-div">
                        <i className="fas fa-handshake"></i>
                        <p className="bold-font">Thank you for your message!</p>
                        <p>We will review your inquiry promptly and respond as soon as possible. If your message requires urgent attention, we will prioritize it accordingly.</p>
                    </div>
                ) : (
                    <div className="contact-response-div">
                        <i className="fas fa-sad-cry"></i>
                        <p className="bold-font">Oops!</p>
                        <p>It seems like there was a hiccup. Feel free to give it another shot, or if the issue persists, reach out to us via email at viraltreasures.store@gmail.com. We apologize for any inconvenience and appreciate your patience.</p>
                    </div>
                )
            }


        </div>)
}