import React, { useState } from "react";
import { subscribeToNewsletter } from "../utilities/subscribeToNewsletter";

export default function NewsletterSubscription() {

    const [email, setEmail] = useState('')

    const handleSubscribeToNewsletter = async (e) => {
        e.preventDefault()
        let result = await subscribeToNewsletter(email)

        if (result)
            setEmail('')
    }

    return (
        <form onSubmit={handleSubscribeToNewsletter}>
            <div className="footer-email-input-div">
                <input required onChange={(e) => setEmail(e.target.value)} value={email} className="footer-email-input" placeholder="Enter your email" type="email" />
                <button className="subscribe-button">Subscribe</button>
            </div>
        </form>
    )
}